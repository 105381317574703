// 
// popover.scss
//

.popover-header {
    margin-top: 0;
}
.popover-trigger {
    .popover {
        opacity: 0;
        white-space: nowrap;
        transition: opacity .3s;
    }
    &:hover {
        .popover {
            opacity: 1
        }
    }
}