//
// dropzone.scss
//

.dropzone {
  border: 2px dashed $input-border-color;
  background: $input-bg;
  border-radius: 6px;
  cursor: pointer;
  padding: 20px;
  box-shadow: $components-shadow-sm;
}