
// 
// social.scss
//

.social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 2px);
    display: block;
    border: 2px solid #{map-get($grays, "500")};
    border-radius: 50%;
    color: #{map-get($grays, "500")};
}  


// Dark Mode
body[data-layout-color="dark"] {
    .social-list-item {
        border: 2px solid #{map-get($dark-grays, "500")};
        color: #{map-get($dark-grays, "500")};
    } 
}