//
// topbar.scss
//

// Logo
.logo {
    display: block;

    span.logo-lg {
        display: block;
    }

    span.logo-sm {
        display: none;
    }

    .logo-lg-text-dark {
        color: #{map-get($grays, "900")};
        font-weight: $font-weight-bold;
        font-size: 22px;
        text-transform: uppercase;
    }

    .logo-lg-text-light {
        color: $primary;
        font-weight: $font-weight-bold;
        font-size: 22px;
        text-transform: uppercase;
    }
}

.logo-box {
    height: $topbar-height;
    width: $logo-box-width;
    float: left;
    transition: all 0.1s ease-out;

    .logo {
        line-height: $topbar-height;
    }
}

.logo-light {
    display: $logo-light-display;
}

.logo-dark {
    display: $logo-dark-display;
}

.bordered-nav {
    border-bottom: 2px $primary solid;
}

.navbar-custom {
    background-color: $bg-topbar-dark;
    box-shadow: $box-shadow;
    padding: 0 10px 0 0;
    height: $topbar-height;
    z-index: 1001;

    .topnav-menu {
        > li {
            float: left;
        }

        .nav-link {
            padding: 0 15px;
            color: $primary;
            min-width: 32px;
            display: block;
            line-height: $topbar-height;
            text-align: center;
            max-height: $topbar-height;
        }
    }

    .dropdown {
        .nav-link.show {
            background-color: rgba($white, 0.05);
        }
    }

    .container-fluid {
        padding: 0;
    }

    /* Search */
    .app-search {
        height: $topbar-height;
        display: grid;
        width: 180px;
        margin-right: 20px;
        align-items: center;

        .react-select__placeholder,
        input::placeholder {
            font-size: 0.8125rem;
            color: rgba($white, 0.3) !important;
            margin: 0;
        }

        .react-select__menu {
            overflow: hidden;
            width: 320px !important;
            margin-top: 0;
        }

        .react-select__input,
        .react-select__value-container {
            color: $white !important;
            padding: 0;
        }

        .form-control,
        .react-select__control {
            border: none;
            height: 38px;
            padding-left: 20px;
            padding-right: 0;
            color: $white;
            background-color: rgba($white, 0.07);
            box-shadow: none;
            border-radius: 30px 30px 30px 30px;
        }

        .input-group-text {
            margin-left: 0 !important;
            z-index: 4;
        }

        .btn {
            // background-color: rgba($white, 0.07);
            border-color: transparent;
            color: rgba($white, 0.3);
            border-radius: 0 30px 30px 0;
            box-shadow: none !important;
        }
    }

    .button-menu-mobile {
        border: none;
        color: $white;
        display: inline-block;
        height: $topbar-height;
        line-height: $topbar-height;
        width: 60px;
        background-color: transparent;
        font-size: 24px;
        cursor: pointer;
    }

    .button-menu-mobile.disable-btn {
        display: none;
    }
}

/* Notification */
.noti-scroll {
    max-height: 230px;
}

.notification-list {
    margin-left: 0;

    .noti-title {
        background-color: transparent;
        padding: 15px 20px;
    }

    .noti-icon-badge {
        display: inline-block;
        position: absolute;
        top: 16px;
        right: 10px;
    }

    .notify-item {
        padding: 12px 20px;

        .notify-icon {
            float: left;
            height: 36px;
            width: 36px;
            font-size: 18px;
            line-height: 36px;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            color: $white;
        }

        .notify-details {
            margin-bottom: 5px;
            overflow: hidden;
            margin-left: 45px;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #{map-get($grays, "800")};

            b {
                font-weight: 500;
            }

            small {
                display: block;
            }

            span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
            }
        }

        .user-msg {
            margin-left: 45px;
            white-space: normal;
            line-height: 16px;
        }
    }

    .profile-dropdown {
        .notify-item {
            padding: 7px 20px;
        }
    }
}

.noti-icon {
    font-size: 21px;
    vertical-align: middle;
}

.profile-dropdown {
    min-width: 170px;

    i {
        vertical-align: middle;
        margin-right: 5px;
    }
}

.nav-user {
    padding: 0 12px !important;

    img {
        height: 32px;
        width: 32px;
    }
}

// Full Screen
.fullscreen-enable {
    [data-toggle="fullscreen"] {
        .fe-maximize::before {
            content: "\e88d";
        }
    }
}

@include media-breakpoint-down(lg) {
    .logo-box {
        width: $leftbar-width-condensed !important;
        padding-right: 0 !important;

        .logo-lg {
            display: none !important;
        }

        .logo-sm {
            display: block !important;
        }
    }
}

@media (max-width: 600px) {
    .navbar-custom {
        .dropdown {
            position: static;

            .dropdown-menu {
                left: 10px !important;
                right: 10px !important;
            }
        }
    }
}

// Light Topbar

body[data-topbar-color="light"] {
    .navbar-custom {
        background-color: $bg-topbar-light !important;
        box-shadow: $box-shadow-sm;

        .topnav-menu {
            .nav-link {
                color: #{map-get($grays, "700")};
            }
        }

        .dropdown {
            .nav-link.show {
                background-color: #{rgba(map-get($grays, "900"), 0.03)};
            }
        }

        .button-menu-mobile {
            color: #{map-get($grays, "900")};
        }

        /* Search */
        .app-search {
            .react-select__placeholder,
            input::placeholder {
                color: #{map-get($grays, "500")} !important;
            }

            .form-control,
            .react-select__control {
                color: #{map-get($grays, "900")};
                background-color: #{map-get($grays, "100")};
                border-color: #{map-get($grays, "100")};
            }

            .react-select__input,
            .react-select__value-container {
                color: #{map-get($grays, "900")} !important;
            }

            .btn {
                background-color: #{map-get($grays, "100")};
                color: #{map-get($grays, "400")};
            }
        }
    }

    .logo-dark {
        display: block;
    }

    .logo-light {
        display: none;
    }
}

@include media-breakpoint-down(lg) {
    body[data-layout-mode="horizontal"] {
        // Navbar Button - Horizontal
        .navbar-toggle {
            border: 0;
            position: relative;
            padding: 0;
            margin: 0;
            cursor: pointer;

            .lines {
                width: 25px;
                display: block;
                position: relative;
                height: 16px;
                transition: all 0.5s ease;
                margin-top: calc(#{$topbar-height - 16px} * 0.5);
            }

            span {
                height: 2px;
                width: 100%;
                background-color: #{map-get($grays, "700")};
                display: block;
                margin-bottom: 5px;
                transition: transform 0.5s ease;
            }

            &.open {
                span {
                    position: absolute;

                    &:first-child {
                        top: 7px;
                        transform: rotate(45deg);
                    }

                    &:nth-child(2) {
                        visibility: hidden;
                    }

                    &:last-child {
                        width: 100%;
                        top: 7px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

// Layout Horizontal
body[data-layout-mode="horizontal"] {
    .button-menu-mobile {
        display: none;
    }

    .logo-box {
        width: auto;
        padding-right: 50px;
        background-color: transparent;
    }

    @media (min-width: 992px) and (max-width: 1366px) {
        .logo-box {
            padding-left: 20px;
        }
    }
}

@media (max-width: 360px) {
    .navbar-custom {
        .topnav-menu {
            .nav-link {
                padding: 0 12px;
            }
        }

        .button-menu-mobile {
            width: 45px;
        }
    }
}

// Layout Two Column
body[data-layout-mode="two-column"] {
    &[data-topbar-color="dark"] {
        .navbar-custom {
            .logo-box {
                .logo-light {
                    display: $logo-dark-display;
                }

                .logo-dark {
                    display: $logo-light-display;
                }
            }
        }

        &[data-leftbar-color="dark"] {
            .navbar-custom {
                .logo-box {
                    .logo-light {
                        display: $logo-light-display;
                    }

                    .logo-dark {
                        display: $logo-dark-display;
                    }
                }
            }
        }

        &[data-leftbar-color="brand"],
        &[data-leftbar-color="gradient"] {
            .navbar-custom {
                .logo-box {
                    .logo-light {
                        display: block;
                    }

                    .logo-dark {
                        display: none;
                    }
                }
            }
        }
    }
}

// Dark Mode
body[data-layout-color="dark"] {
    .logo {
        .logo-lg-text-dark {
            color: #{map-get($dark-grays, "900")};
        }
    }

    .notification-list {
        .notify-item {
            .notify-details {
                color: #{map-get($dark-grays, "800")};
            }
        }

    }

    .navbar-custom {
        .app-search {
            .react-select__placeholder,
            input::placeholder {
                color: #{map-get($dark-grays, "500")} !important;
            }

            .react-select__control,
            .form-control {
                color: #{map-get($dark-grays, "900")};
                background-color: #{map-get($dark-grays, "100")};
                border-color: #{map-get($dark-grays, "100")};
            }

            .btn {
                background-color: #{map-get($dark-grays, "100")};
                color: #{map-get($dark-grays, "400")};
            }
        }
    }

    &[data-topbar-color="light"] {
        .navbar-custom {
            // .logo-box {
            //     .logo-light {
            //         display: block;
            //     }

            //     .logo-dark {
            //         display: none;
            //     }
            // }
            .topnav-menu {
                .nav-link {
                    color: #{map-get($dark-grays, "700")};
                }
            }

            .dropdown {
                .nav-link.show {
                    background-color: #{rgba(map-get($dark-grays, "900"), 0.03)};
                }
            }

            .button-menu-mobile {
                color: #{map-get($dark-grays, "900")};
            }
        }
    }

    &[data-layout-mode="horizontal"][data-topbar-color="light"],
    &[data-layout-mode="default"][data-leftbar-color="light"][data-topbar-color="light"],
    &[data-layout-mode="detached"][data-topbar-color="light"],
    &[data-sidebar-icon="twotones"][data-topbar-color="light"][data-leftbar-color="light"] {
        .navbar-custom {
            .logo-box {
                .logo-light {
                    display: block;
                }

                .logo-dark {
                    display: none;
                }
            }
        }
    }

    &[data-layout-mode="horizontal"] {
        @include media-breakpoint-down(lg) {
            .navbar-toggle {
                span {
                    background-color: #{map-get($dark-grays, "700")};
                }
            }
        }
    }
}
