// 
// accordions.scss
//

.custom-accordion {
    .accordion-arrow {
        font-size: 1.2rem;
        position: absolute;
        right: 0;
    }
    a {
        &.collapsed {
            i.accordion-arrow {
                &:before {
                    content: "\F0142";
                }
            }
        }
    }
}
.accordion-header {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.accordion-button {
    padding: 0.7rem 1.25rem;
    &:focus {
        border: none;
        box-shadow: none;
    }
    &:not(.collapsed) {
        color: $white;
        background-color: $purple-100;
        box-shadow: none;
    }
    &::after, &:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
}