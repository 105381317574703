//
// forms.scss
//

.form-control,
.form-select {
    box-shadow: $components-shadow-sm;
}

// Form-control light
.form-control-light {
    background-color: $input-light-bg !important;
    border-color: $input-light-bg !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid,.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: #{map-get($grays, "300")};
        border-radius: $input-border-radius $input-border-radius 0 0;
    }
    .comment-area-btn {
        background-color: #{map-get($grays, "100")};
        padding: 10px;
        border: 1px solid #{map-get($grays, "300")};
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Search box 
.search-bar {

    .form-control {
        padding-left: 40px;
        padding-right: 20px;
        border-radius: 30px;
    }

    span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: $input-height;
        left: 13px;
        top: -2px;
        color: $text-muted;
    }
}

// Show Password
.password-eye {
    &:before {
        font-family: feather!important;
        content: "\e86a";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\e86e";
        }
    }  
}

.form-switch {
    .form-check-input {
        cursor: pointer;
    }
    /*
    * Change the size of switch input with *-sm, *-lg, *-md(...) classes
    * Source: Nisharg Shah on https://codepen.io/
    */
    @mixin switch($res: 'sm') {
        $index: 1rem;
        $mainVal: 1rem;
        @if $res == 'md' {
            $index: 2rem;
            $mainVal: 1.5rem;
        } @else if $res == 'lg' {
            $index: 3rem;
            $mainVal: 2rem;
        } @else if $res == 'xl' {
            $index: 4rem;
            $mainVal: 2.5rem;
        }
        .form-check-input {
            height: $mainVal;
            width: calc(#{$index} + 0.75rem);
            border-radius: $mainVal * 2;
        }
    }
    .form-check-input {
        clear: left;
    }
    // sm( - DEFAULT), md, lg, xl
    &.form-switch-sm {
        @include switch();
    }
    &.form-switch-md {
        @include switch('md');
    }
    &.form-switch-lg {
        @include switch('lg');
    }
    &.form-switch-xl {
        @include switch('xl');
    }
}

label:not(.actions-row label, label.form-check-label), .label {
    font-weight: bold;
    margin: 1rem 0 .25rem 0;
}


// Dark Mode
body[data-layout-color="dark"] {
    .comment-area-box {
        .form-control {
            border-color: #{map-get($dark-grays, "300")};
        }
        .comment-area-btn {
            background-color: #{map-get($dark-grays, "100")};
            border: 1px solid #{map-get($dark-grays, "300")};
        }
    }
}