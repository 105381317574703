// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

small, .small {
  font-size: 0.60rem;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.text-orange {
  color: $orange;
}

.text-font-tertiary {
  font-family: "Corbel" !important;
}

p {
  margin: 0;
}

@mixin text-outline($color) {
  text-shadow: 1px 1px 0 $color, 1px -1px 0 $color, -1px 1px 0 $color, -1px -1px 0 $color, 1px 0px 0 $color, 0px 1px 0 $color, -1px 0px 0 $color, 0px -1px 0 $color;
}

@each $color, $value in $theme-colors {
  .text-outline-#{$color} {
    @include text-outline($value);
  }
}

@include media-breakpoint-down(lg) {
  .lg\:overflow-auto {
    overflow: auto;
  }
  .lg\:pb-4 {
    padding-bottom: 2.1rem;
  }
  .lg\:mb-0 {
    margin-bottom: 0.5rem !important;
  }
}